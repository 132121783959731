import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router,Params } from '@angular/router';
import { AuthService } from '../../core/auth/auth.service';


@Component({
  selector: 'app-marketplacelogin',
  templateUrl: './marketplacelogin.component.html',
  styleUrls: ['./marketplacelogin.component.css']
})
export class MarketplaceloginComponent implements OnInit {

  lnskey: any;
    [x: string]: any;
    loginForm: FormGroup;
    public receivedToken:any;
    year: number = new Date().getFullYear();
    currentUser = {};
    errMsg: string;
    forgot : FormGroup;
    hide = true;
    message: string;
    isSubmitted: boolean;
    validurl: boolean = false;
    decodeToken: any;
    passwordType: string='password';
    passwordShown: boolean = false;
    getToken:any;
    public flag: any;
    public apicallstatus: any = false;
    isLoading: boolean = false;

  constructor(public router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private AuthService: AuthService,) { }

  ngOnInit() {
    // this.isValid = localStorage.getItem("backToken");
    localStorage.setItem("backToken","1"); 
    localStorage.removeItem('user');
    // this.href = this.router.url;
    // this.receivedToken=this.href.split('%20').pop()
    this.route.params.subscribe(params => {
    this.receivedToken = params['id'];         
    console.log("LNSKEY ID INIT",this.receivedToken);
     });
    console.log('print',this.receivedToken);
    console.log(this.router.url);
    this.ValidateURL();

    
    // this. getTokenData();

      this.loginForm = this.formBuilder.group({
        username: ["", Validators.required],
          password: ["", Validators.required],
      });
      this.forgot = this.formBuilder.group({
        username: ['', Validators.required]
        });    
      setInterval(() => this.login(), 5000);
  }
  // login() {
  //   let formValues = this.loginForm["value"];

  //   if (formValues.username != "" && formValues.password != "") {
  //     if (typeof localStorage.getItem('user') !== 'undefined')
  //       localStorage.removeItem('user');

  //     console.log(this.loginForm["value"]);
  //     this.apiCallsService.login(this.loginForm["value"]).subscribe(
  //       (data) => {
  //         localStorage.setItem("user", JSON.stringify(data));

  //         // const token = data.token.substr(2, data.token.length - 3);
        
  //         // const stringJSONToken = JSON.stringify({ token: token });

  //         // localStorage.setItem("api", stringJSONToken);


  //         this.router.navigate(["dashboard"]);
  //       }
  //       // (err) => {
  //       //   // this.authFailed = err["error"]["message"];
  //       //   this.loginForm.controls["username"].setErrors({ incorrect: true });
  //       //   this.loginForm.controls["password"].setErrors({ incorrect: true });
  //       //   this.loginForm.controls["user_timezone"].setErrors({
  //       //     incorrect: true,
  //       //   });
  //       // }
  //     );
  //   } 
  //   else return;
  // }

  login() {
    this.ValidateURL();
  //  if (this.loginForm.valid) {
    if (this.validurl) {
      this.isLoading = true;
      this.loginForm['value']['username'] = 'livnsense';
      this.loginForm['value']['password'] = 'lns@123';
      this.loginForm['value']['user_timezone'] = 'Asia/Calcutta';
      this.apiCallsService.login(this.loginForm['value']).subscribe(data => {
        localStorage.setItem('user', JSON.stringify(data));
        this.isLoading = false;
        this.router.navigate(['unit']);
      }, err => {
        this.isLoading = false;
        // this.authFailed = err['error']['message'];
        // console.log(this.authFailed);
        this.loginForm.controls['username'].setErrors({ incorrect: true });
        this.loginForm.controls['password'].setErrors({ incorrect: true });
      })
    }
    else
      return;
  }


  public  ValidateURL(){
      console.log('receivedToken',this.receivedToken);
      console.log('getToken',this.getToken);
    if(this.receivedToken==this.getToken){
      console.log('pass')
      this.flag=1;
    }else{
      console.log('failed')
      this.flag=0;
    }
  
  }
  async getTokenFromDb(){
    this.isLoading = true;
    console.log(this.recivedToken)
    var response = await this.AuthService.marketLogin(this.receivedToken).toPromise();
  
      this.isLoading = false;
      this.getToken = response[0]['token'];
      this.apicallstatus = true;
    
      const emptyToken = "";
      const newData = {project_code:'demo1', token:this.receivedToken};
  
           this.AuthService.updateToken(newData).subscribe((res)=>{
           console.log(emptyToken);
           console.log(res);
          console.log(newData);
           })
    
  }
  async  navHome() {

    await this.getTokenFromDb();
    this.ValidateURL();
    console.log('print',this.flag);
    console.log('print',this.receivedToken);
    console.log('print',this.getToken);
    if(this.flag=1){
    this.AuthService.tempLogin().subscribe((res)=>{
      console.log("working");
      localStorage.setItem('user', JSON.stringify(res));
      localStorage.setItem("backToken","1");
      console.log("woksFOr")
    this.router.navigateByUrl('dashboard')
  })}else{
    console.log("woksFOr")
    this.router.navigateByUrl('auth/pharmalogin')
  }
  }
}
function body(body: any) {
  throw new Error('Function not implemented.');
}
