import { Component, OnInit, Input } from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);

@Component({
  selector: 'app-tosylate',
  templateUrl: './tosylate.component.html',
  styleUrls: ['./tosylate.component.css']
})
export class TosylateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }


  testChart1(ph_value1){
    let thisRef=this;
      // Themes begin
      am4core.useTheme(am4themes_animated);
      // Themes end
  
      // Create chart instance
      let chart: any = am4core.create('line-chart', am4charts.XYChart);
      chart.background.fill = '#0C101A';
      chart.responsive.enabled = true;
      chart.timezoneOffset = 300;
      chart.paddingRight = 25;
      chart.logo.disabled = true;
  
      // Set input format for the dates
      chart.dateFormatter.inputDateFormat = 'yyyy-MM-dd H-mm';
  
      //Add scrollbar
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.parent = chart.topAxesContainer;
      chart.scrollbarX.startGrip.background.fill = am4core.color("#08f");
      chart.scrollbarX.endGrip.background.fill = am4core.color("#08f");
      chart.scrollbarX.thumb.background.fill = am4core.color("#08f");

      chart.scrollbarX.startGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.endGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.minHeight = 7;
      // Applied on hover
      chart.scrollbarX.startGrip.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");

      // Applied on mouse down
      chart.scrollbarX.startGrip.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");
  
      // Add legend
      chart.legend = new am4charts.Legend();
      chart.legend.position = 'top';
      chart.legend.labels.template.fill = am4core.color('#fff');
      chart.legend.labels.template.fontWeight = 'bold';
      // let data = paramData;
      // chart.data = data;
      chart.data = [{
        "year": "1930",
        "italy": 1,
        "germany": 5,
        "uk": 3
      }, {
        "year": "1934",
        "italy": 1,
        "germany": 2,
        "uk": 6
      }, {
        "year": "1938",
        "italy": 2,
        "germany": 3,
        "uk": 1
      }, {
        "year": "1950",
        "italy": 3,
        "germany": 4,
        "uk": 1
      }, {
        "year": "1954",
        "italy": 5,
        "germany": 1,
        "uk": 2
      }, {
        "year": "1958",
        "italy": 3,
        "germany": 2,
        "uk": 1
      }, {
        "year": "1962",
        "italy": 1,
        "germany": 2,
        "uk": 3
      }, {
        "year": "1966",
        "italy": 2,
        "germany": 1,
        "uk": 5
      }, {
        "year": "1970",
        "italy": 3,
        "germany": 5,
        "uk": 2
      }, {
        "year": "1974",
        "italy": 4,
        "germany": 3,
        "uk": 6
      }, {
        "year": "1978",
        "italy": 1,
        "germany": 2,
        "uk": 4
      }];
  
      // chart.events.on('beforedatavalidated', function (ev: any) {
      //   chart.data.sort(function (a: { ins_time: string | number | Date; }, b: { ins_time: string | number | Date; }) {
      //     return (
      //       new Date(a.ins_time).valueOf() -
      //       new Date(b.ins_time).valueOf()
      //     );
      //   });
      // });
  
      // Create axes
      let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.grid.template.location = 0;
      dateAxis.renderer.minGridDistance = 80;
      dateAxis.title.text = 'Date';
      dateAxis.title.fill = '#fff';
      dateAxis.title.fontWeight = 'bold';
      dateAxis.renderer.labels.template.fill = am4core.color('#fff');
      dateAxis.renderer.labels.template.fontSize = 15;
      dateAxis.renderer.grid.template.stroke = "#fff";

      
      dateAxis.baseInterval = {
        timeUnit: 'hour',
        count: 1,
      };
      dateAxis.skipEmptyPeriods = true;
      // dateAxis.tooltipDateFormat = 'yyyy-MM-dd HH-mm';
      dateAxis.tooltipDateFormat = 'd MMM, hh-mm a';
  
      // Add cursor
      chart.cursor = new am4charts.XYCursor();
      // chart.cursor.snapToSeries = series;
      chart.cursor.xAxis = dateAxis;
      chart.legend.markers.template.disabled = true;
      // chart.cursor.dx = -44;
  
      // Create series
      function createAxisAndSeries(pName: string, field: any, color: string) {
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  
        
        valueAxis.title.text = pName;
        valueAxis.title.fill = '#fff';
        valueAxis.title.fontWeight = 'bold';
        valueAxis.renderer.grid.template.stroke = "#fff";
        valueAxis.renderer.labels.template.fontSize = 15;
        if (chart.yAxes.indexOf(valueAxis) != 0) {
          valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
        }
  
        let series = chart.series.push(new am4charts.LineSeries());
        let tempValue = field;
  
        series.dataFields.valueY = field;
        series.dataFields.dateX = 'date';
        series.strokeWidth = 4;
        series.yAxis = valueAxis;
        // series.name = pName;
        series.tooltipText =
          pName + 'ph_value1';
        series.tooltip.pointerOrientation = 'vertical';
        series.tooltip.getFillFromObject = false;
        series.tooltip.background.fill = color;
        series.tooltip.label.fill = am4core.color('#fff');
        // series.tooltip.label.fontSize = 17;
        series.stroke = color;
        // series.tensionX = 0.8;
        // series.showOnInit = true;
  
        let interfaceColors = new am4core.InterfaceColorSet();
  
        valueAxis.renderer.line.strokeOpacity = 1;
        valueAxis.renderer.line.strokeWidth = 2;
        valueAxis.renderer.line.stroke = series.stroke;
        valueAxis.renderer.labels.template.fill = series.stroke;
        valueAxis.cursorTooltipEnabled = true;
        valueAxis.renderer.labels.template.fill = am4core.color('#fff');

      }
  
      createAxisAndSeries(ph_value1, ph_value1, '#08f');
    }
}
