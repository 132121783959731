// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  lnsURL: 'https://products.livnsense.com/#/dashboard/landingpage', 
  baseUrl: 'https://api.new-pharma.livnsense.com/',
  graphs: [{
    name: 'reactor',
    graph: {
      reactorRpm: ['MultiLineChartComponent'],
      phValue: ['MultiLineChartComponent'],
      reactorWeight: ['MultiLineChartComponent'],
      reactorPressure: ['MultiLineChartComponent'],
      massTemp: ['MultiLineChartComponent'],
      jacketTemp: ['MultiLineChartComponent'],
      availability: ['MultiLineChartComponent'],
      performance: ['BarChartComponent'],
      quality: ['PieChartComponent']
    }
  }],

  inputtags: [
    { name: 'Temperature of mass' },
    { name: 'RPM' },
    { name: 'pH value' },
  ],
};
