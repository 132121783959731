import { Component } from "@angular/core";

/* import {
  sideNavAnimation,
  sideNavContainerAnimation
} from './sidenav.animation'; */
import { MatSidenav } from "@angular/material";
import {
  sideNavAnimation,
  sideNavContainerAnimation,
} from "./routes/dashboard/pages/dashboard/sidenav.animation";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent {
  
}
