import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { MarketplaceloginComponent } from '../app/routes/marketplacelogin/marketplacelogin.component';


const routes: Routes = [

  {
    path: 'auth',
    loadChildren: () =>
      import('@app/routes/authentication/authentication.module').then(m => m.AuthenticationModule),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('@app/routes/dashboard/dashboard.module').then(m => m.DashboardModule),
      canActivate: [AuthGuard]
  },
  {
    path: 'reactor',
    loadChildren: () =>
      import('@app/routes/production-reactor/production-reactor.module').then(m => m.ProductionReactorModule),
      canActivate: [AuthGuard]
  },
  { path: 'pharmalogin/:id', component: MarketplaceloginComponent },
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'auth'
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
