import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from './../../../environments/environment';
import { BehaviorSubject,Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  constructor(private http: HttpClient) { }

  /* Method which returns jwt token */
  public getToken(): string {
    if (localStorage.getItem('user')) {
      return JSON.parse(localStorage.getItem('user'))['token'];
    }
  }

  /* Method to check token expiry */
  public isAuthenticated(): boolean {
    const token = this.getToken();

    // commented below as we didn't implement token validity
    // return token ? helper.isTokenExpired(token) : false;

    // remove below line if we implement token validity
    return token ? true : false;
  }

  /* Method returns decoded token */
  public getLoggedUserPermissions(): object {
    const token = this.getToken();

    const decoded = helper.decodeToken(token);

    return decoded['permission'];
  }

  /* Method which returns the role of logged user */
  public getLoggedUserRole(): string {
    const token = this.getToken();

    const decoded = helper.decodeToken(token);
    return decoded['role'];
  }

  getResetLoginId(token) {

    const decoded = helper.decodeToken(token);

    return decoded;
  }

  login(username: string, password: string,user_timezone:string) {
    return this.http
      .post<any>(`${environment.baseUrl}auth`, { username, password, user_timezone })
      .pipe(
        map((user) => {
        
          if (user) {
            // console.log(user);
            // store user details in local storage to keep user logged in
            localStorage.setItem('user', JSON.stringify(user));                 
          }
          return user;
         
        })
      );
  }

  tempLogin(): Observable<any> {
    const user = {
      username: "livnsense",
      password: "lns@123",
      user_timezone: "'Asia/Calcutta'"
    }
    return this.http.post(`${environment.baseUrl}auth`, user)
      .pipe(map((response: any) => {
        return response
      }, (error: any) => {
        console.log(error);
      }
      ));
  }

  marketLogin(receivedToken:any): Observable<any> {
    // return this.http.get(`https://api.demo1.livnsense.com/dcu/get_algo_token?project_id=1`).pipe(
      return this.http.get(`https://api.new-demo1.livnsense.com/dcu/get_algo_token?token=`+receivedToken).pipe(

      map(
        (response: any) => {
          return response;
        },
        ( err: any) => {
          console.log(err);
        }
      )
    );
  } 
  updateToken(body:any) {
    return this.http
      .post<any>(`https://api.new-demo1.livnsense.com/dcu/algo_token_post?action=D`, body);
  }

}
